.carousel-item {
    /*height: 400px;*/
    /*background-color: #E4E4E4;*/
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.carousel-item .content {
    margin-right: 20%;
    margin-left: 20%;
}

/*.carousel-item .description {*/
/*    padding-top: 2%;*/
/*}*/

