.header {
    background-color: #ffffffe6;
    width: 100%;
    position: sticky;
    top: 0;
    padding: 20px 0;
    z-index: 3;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 100%;
}
.main-menu-list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    align-items: center;
}
.menu-item {
    margin-right: 24px;
    cursor: pointer;
}

.menu-item-title {
    display: block;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    color: #6A6A6A;
    text-decoration: none;
    padding: 0;
    position: relative;
}
.contact-button {
    display: block;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    text-decoration: none;
    position: relative;
    padding: 4px 54px;
    color: white;
    background: #035370;
    border: 2px solid #035370;
    border-radius: 8px;
    outline: none;

    &:hover {
        color: white;
        cursor: pointer;
    }
}

.menu .main-menu-list .menu-item:last-child {
    margin-right: 0;
}

.dropdown-item {
    margin-bottom: 8px;
}

@media screen and (max-width: 991px) {
    .header {
        padding: 8px 0;
    }
}

@media screen and (max-width: 575px) {
    .header {
        padding: 12px 0;

        .logo {
            padding-left: 4px;

            a {
                img {
                    max-height: 40px;
                }
            }
        }
    }
}

.menu-bar, .mobile-menu {
    display: none;
}

@media screen and (max-width: 1399px) {
    .menu {
        ul {
            li {
                margin-right: 20px;
            }
        }
    }
}
@media screen and (max-width: 1199px) {
    .menu {
        ul {
            li {
                margin-right: 12px;
            }
        }
    }
}
@media screen and (max-width: 991px) {
    .menu {
        display: none;
    }

    .menu-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        height: 100%;

        .toggle-menu {
            display: inline-block;
            width: 32px;
            height: 32px;
            color: transparent;
            position: relative;

            &:before {
                content: '';
                background: url(../../assets/images/down.png) no-repeat;
                background-size: contain;
                width: 32px;
                height: 32px;
                position: absolute;
                left: 0;
                top: 0;
            }

            &.close {
                &:before {
                    background: url(../../assets/images/down.png) no-repeat;
                    background-size: contain;
                }
            }
        }
    }

    header {
        &.small {
            .menu-bar{
                .toggle-menu {

                    &:before {
                        background: url(../../assets/images/down.png) no-repeat;
                        background-size: contain;
                    }

                    &.close {
                        &:before {
                            background: url(../../assets/images/close.png) no-repeat;
                            background-size: contain;
                        }
                    }
                }
            }
        }
    }

    .mobile-menu {
        display: none;
        position: absolute;
        left: 0;
        top: 100%;
        width: 100vw;
        height: calc(100vh - 64px);
        padding-bottom: 70px;
        background: white;
        border-top: 1px solid #E0E0E0;
        overflow-y: auto;

        ul {
            padding: 0 12px;
            margin: 0 auto;
            width: 720px;

            li {
                list-style: none;
                padding-left: 0;
                border-bottom: 1px solid #E0E0E0;
                position: relative;

                &.menu-item-has-children {
                    a {
                        pointer-events: none;
                    }

                    &:after {
                        content: none;
                    }

                    .menudrop {
                        width: 26px;
                        height: 40px;
                        position: absolute;
                        right: 0;
                        top: 12px;
                        display: block;
                        z-index: 1;

                        &:before {
                            content: '';
                            background: url(../../assets/images/down.png) no-repeat;
                            background-size: contain;
                            width: 30px;
                            height: 30px;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            transition: all 0.3s ease;
                        }

                        &.turn {

                            &:before {
                                transform: rotate(180deg) translate(50%, 100%);
                            }
                        }
                    }

                    .subMenu {
                        padding-left: 0;
                        padding-right: 0;
                        margin: 0;
                        width: 100%;
                        display: none;

                        li {
                            border-bottom: 1px solid #F8F8F8;

                            &:after {
                                content: none;
                            }

                            &.current-menu-item {
                                background: #F8F8F8;
                            }

                            &:last-child {
                                border-bottom: none;
                            }

                            a {
                                padding: 14px 24px;
                                display: block;
                                position: relative;
                                font-weight: 500;
                                font-size: 20px;
                                line-height: 30px;
                                color: #363636;
                                pointer-events: all;
                            }
                        }
                    }
                }

                a {
                    text-decoration: none;
                    font-size: 24px;
                    line-height: 36px;
                    font-weight: 600;
                    color: #363636;
                    padding: 16px 0;
                    display: block;
                }

                &.current-menu-item {
                    a {
                        color: #0F336F;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .menu {
        display: none;
    }
    .menu-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        height: 100%;

        .toggle-menu {
            display: inline-block;
            width: 32px;
            height: 32px;
            color: transparent;
            position: relative;

            &:before {
                content: '';
                background: url('../../assets/images/menu.svg') no-repeat;
                background-size: contain;
                width: 32px;
                height: 32px;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .menu-bar {
        .toggle-menu {
            width: 24px;
            height: 24px;

            &:before {
                width: 24px;
                height: 24px;
            }
        }
    }

    .mobile-menu {
        padding-bottom: 120px;

        ul {
            width: 540px;
        }
    }
}

@media screen and (max-width: 575px) {
    .menu-bar {
        padding-right: 4px;
    }

    .mobile-menu {
        ul {
            width: 100%;
            padding: 0;

            li {
                padding: 0 24px 0 32px;

                &:after {
                    right: 30px;
                }

                &.current-menu-item {
                    a {
                        font-weight: 700;
                    }
                }


                &.menu-item-has-children {
                    .menudrop {
                        right: 30px;
                    }

                    .subMenu {
                        margin: 0 -7px;
                        width: calc(100% + 7px);

                        li {
                            padding: 0;

                            a {
                                padding: 13px 24px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .mobile-menu {
        ul {
            li {
                a {
                    font-size: 20px;
                    line-height: 30px;
                }

                .subMenu {
                    li {
                        a {
                            font-size: 16px !important;
                            line-height: 24px !important;
                        }
                    }
                }
            }
        }
    }
}
