.hero-banner-widget {
    position: relative;
    display: block;
    padding: 0px;
    background-color: #071835;
}

.banner-img {
    height: 700px;
    width: 100%;
    object-fit: cover;
}

.hero-banner-widget .overlayContent {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    overflow: hidden;
    z-index: 1;
}

.hero-banner-widget .overlayContent .textArea {
    display: block;
    margin: 0px;
    padding: 80px 0px;
    max-width: 640px;
    overflow: hidden;
    color: white;
}

.hero-banner-widget .overlayContent .textArea h1 {
    color: white;
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
    margin: 0px;
    padding: 0px;
}

.hero-banner-widget .overlayContent .textArea p:first-of-type {
    padding-top: 10px;
}

.hero-banner-widget .overlayContent .textArea p {
    display: block;
    margin: 0px;
    padding: 0px;
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    color: white;
}
