.bancontent {
    padding: 60px 8px;
    background: linear-gradient(83.06deg, #D7FFF3 2.03%, #D7FAFF 8.19%, #E3DCFF 76.06%, #D7FFF3 98.61%);
    background-size: cover;
    background-position: right;
    border-radius: 24px;
    overflow: hidden;
}

.bancontent h3 {
    color: #363636;
    margin-bottom: 2%;
}

.bancontent .row .contact-button {
    padding: 8px 54px;
    color: white;
    background: #035370;
    border: 2px solid #035370;
    border-radius: 8px;
    outline: none;
    font-size: 16px;
}

.icon-wrap img {
    width: 100%;
}
