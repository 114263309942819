body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body a {
  text-decoration: none;
}

html body .text-navyBlue {
  color: #4759FF;
}

html body .text-lightblue {
  color: #2B58A4;
}

html body .btn-solid {
  outline: none;
  border: none;
  display: inline-block;
  padding: 12px 64px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  &.btn-full {
    width: 100%;
  }
  &.bg-lightblue, &.bg-lightblue:hover {
    color: white;
    background: #035370;
  }
}

html body .btn-solid.bg-white, html body .btn-solid.bg-white:hover {
  color: #2B58A4;
  background: white;
}

body .sectionDescription {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #6A6A6A;
  margin-bottom: 32px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*@media screen and (max-width: 767px) {*/
/*  html body .btn-solid {*/
/*    font-size: 16px;*/
/*    line-height: 24px;*/
/*  }*/
/*}*/

@media screen and (max-width: 767px) {
  .sectionTitle {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 8px;

    /*span {*/
    /*  font-size: 20px;*/
    /*  line-height: 30px;*/
    /*}*/
  }

  /*.sectionDescription {*/
  /*  margin-bottom: 16px;*/
  /*}*/

  /*.middle-content {*/
  /*  align-items: center;*/
  /*}*/

  /*.btn-solid {*/
  /*  font-size: 16px;*/
  /*  line-height: 24px;*/
  /*}*/

  /*.btn-transparent {*/
  /*  font-size: 16px;*/
  /*  line-height: 24px;*/
  /*}*/

  /*.line-link {*/
  /*  &.small {*/
  /*    font-size: 14px;*/
  /*    line-height: 21px;*/
  /*  }*/
  /*}*/
}

@media screen and (max-width: 991px) {
  html body .sectionTitle {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 16px;

    /*span {*/
    /*  font-size: 24px;*/
    /*  line-height: 36px;*/
    /*}*/
  }

  /*.sectionDescription {*/
  /*  font-size: 16px;*/
  /*  line-height: 24px;*/
  /*  margin-bottom: 24px;*/
  /*}*/
}

html body .sectionTitle {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #363636;
  margin-bottom: 24px;
}
