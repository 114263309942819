.widgetBanTxtBtnImg {
    position: relative;
    padding: 60px 0;
    background: #045270;
}

.widgetColTxtImgBul {
    position: relative;
    padding: 60px 0;
    background: white;
}

.page-banner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.page-banner .content {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.page-banner .content .banner-title {
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    color: white;
    margin-bottom: 24px;
}

.page-banner .content .subtitle {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: white;
    margin-bottom: 32px;
}

.page-banner .icon {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
}

@keyframes bounce {
    0% { transform:translateY(0%); }
    50% { transform:translateY(-5%); }
    100% { transform:translateY(0); }
}

.page-banner .icon-animation {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.page-banner .icon-animation img {
    max-width: 100%;
    animation: bounce 2s ease infinite;
}

.widgetColTxtImgBul .horizontalPoints {
    position: relative;
    display: flex;
    flex-direction: column;
}

.widgetColTxtImgBul .horizontalPoints .points {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
}

.widgetColTxtImgBul .horizontalPoints .points .icon {
    width: 48px;
}

.widgetColTxtImgBul .horizontalPoints .points .icon .iconwrap {
    display: block;
    width: 48px;
    height: 48px;
    background: white;
    border-radius: 50%;
    position: relative;
    box-shadow: 0 0 20px #00000014;
}

.widgetColTxtImgBul .horizontalPoints .points .icon .iconwrap img {
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.widgetColTxtImgBul .horizontalPoints .points .text {
    width: calc(100% - 48px);
    padding-left: 16px;
}

.widgetColTxtImgBul .horizontalPoints .points .text .desc {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #6A6A6A;
    margin-bottom: 0;
}

.widgetColTxtImgBul .bannercontainer {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.widgetColTxtImgBul .bannercontainer img {
    max-width: 100%;
    transition: all 0.3s ease;
}

.widgetColrTxt {
    position: relative;
    padding: 60px 0;
    background: white;
}

.widgetColrTxt .bgBlue {
    position: relative;
    background: linear-gradient(90deg, #2b58a4 0%, #5ebce7 100%);;
    border-radius: 24px;
    overflow: hidden;
}

.widgetColrTxt .bgblack {
    position: relative;
    background-color: #424866;
    overflow: hidden;
    border-radius: 24px;
}

.widgetColrTxt .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: white;
    margin-bottom: 32px;
}

.widgetColStepCard {
    position: relative;
    padding: 60px 0;
    background: #F9F9FF;
}

.widgetColStepCard .icondatacard {
    position: relative;
    width: 100%;
    height: calc(100% - 24px);
    margin-bottom: 24px;
    padding: 24px;
    border-radius: 24px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.widgetColStepCard .icondatacard .head {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 8px;
    width: 100%;
}

.widgetColStepCard .icondatacard .body {
    position: relative;
    width: 100%;
}

.widgetColStepCard .icondatacard .head .icon {
    position: relative;
    width: 100%;
    margin-bottom: 8px;
}

.widgetColStepCard .icondatacard .head .text {
    position: relative;
    width: 100%;
}

.widgetColStepCard .icondatacard .head .text .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #363636;
    margin-bottom: 0;
}

.widgetColStepCard .iconTitleDescCard{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: calc(100% - 16px);
    background: white;
    padding: 30px 24px;
    border-radius: 24px;
    margin-bottom: 24px;
    transition: all 0.3s ease;
}

.widgetColStepCard .iconTitleDescCard .head {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding-right: 24px;
}

.widgetColStepCard .iconTitleDescCard .head .icon {
    align-self: center;
    margin-bottom: 8px;
}

.widgetColStepCard .iconTitleDescCard .head .icon img {
    max-width: 100%;
}

.widgetColStepCard .iconTitleDescCard .head .text {
    width: calc(100% - 101px);
    padding-left: 16px;
}
.widgetColStepCard .iconTitleDescCard .head .text .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 0;
    color: black;
}

.widgetColStepCard .iconTitleDescCard .tail {
    width: 70%;
    padding-left: 24px;
    border-left: 1px solid #EAEAEA;
}

.widgetColStepCard .iconTitleDescCard .tail .desc {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
    color: #6A6A6A;
}

@media screen and (max-width: 575px) {
    .widgetColStepCard {
        padding: 40px 4px;
    }
}
@media screen and (max-width: 991px) {
    .widgetColStepCard {
        padding: 60px 0 36px;

        .iconTitleDescCard {
            flex-direction: column;
            align-items: flex-start;
            padding: 24px 24px 48px;

            .head {
                width: 100%;
                padding-right: 0;
                margin-bottom: 8px;

                .icon {
                    width: 67px;
                }

                .text {
                    width: calc(100% - 67px);
                    padding-left: 8px;

                    .title {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
            }

            .tail {
                width: 100%;
                padding-left: 0;
                border-left: none !important;

                .desc {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .widgetColStepCard {
        padding: 40px 0;

        .iconTitleDescCard {
            height: calc(100% - 16px);
            margin-bottom: 16px;

            .head {
                .text {
                    .title {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }

        .carousel{
            padding-bottom: 10px;
        }
    }
}

.widgetColServiceCard {
    position: relative;
    padding: 60px 0;
    background: white;
}

.widgetColCard {
    position: relative;
    padding: 60px 0;
    background: #F9F9FE;
}

.widgetStepper {
    position: relative;
    padding: 60px 0;
    background: white;
}

.widgetCompliance {
    position: relative;
    padding: 60px 0;
    background: #F9F9FE;
}

.widgetColTxtImgBul .horizontalPoints .points .image {
    width: 48px;
}

.widgetColTxtImgBul .horizontalPoints .points .image .imageWrap {
    display: block;
    width: 48px;
    height: 48px;
    background: white;
    border-radius: 50%;
    position: relative;
    box-shadow: 0 0 20px #00000014;
}

.widgetColTxtImgBul .horizontalPoints .points .image .imageWrap img {
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/*.banner {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    object-fit: cover;*/
/*}*/
/*.banner-img {*/
/*    height: 700px;*/
/*    max-height: 810px;*/
/*    width: 100%;*/
/*    object-fit: cover;*/
/*}*/
/*.separator {*/
/*    background-color: #F9F9FE;*/
/*    height: 50px;*/
/*}*/

/*.details {*/
/*    margin-top: 2%;*/
/*    margin-bottom: 2%;*/
/*}*/


/*.container-padding {*/
/*    padding: 40px 0;*/
/*}*/

/*.bgblue {*/
/*background: linear-gradient(90deg, #2b58a4 0%, #5ebce7 100%);*/
/*    padding: 24px;*/
/*    align-items: center;*/
/*}*/

/*.bgblack {*/
/*    background-color: #424866;*/
/*    padding: 24px 24px 0 24px;*/
/*}*/

/*.expertImg {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*}*/

/*.benefit-icon {*/
/*    display: flex;*/
/*    height: 30px;*/
/*    width: 30px;*/
/*    border-radius: 50%;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    !*margin-top: 4px;*!*/
/*    margin-right: 8px;*/
/*}*/

/*.benefit-sec {*/
/*    background-color: #F9F9FE;*/
/*}*/

/*.service.active {*/
/*    color: #071835;*/
/*    font-size: 18px;*/
/*    font-weight: 600;*/
/*}*/

.imgTitleTextCard {
    position: relative;
    background: white;
    padding: 24px;
    border-radius: 24px;
    height: calc(100% - 24px);
    margin-bottom: 24px;
}

.imgTitleTextCard .heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.imgTitleTextCard .heading .icon {
    width: 100%;
    text-align: center;
    margin-bottom: 8px;
}
.imgTitleTextCard .card-description {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #6A6A6A;
    margin-bottom: 0;
}

.imgTitleTextCard .heading .icon img {
    width: 80px;
    height: 80px;
}

/*!*.sectionSubTitle {*!*/
/*!*    color: #2A58A4;*!*/
/*!*    margin-bottom: 24px;*!*/
/*!*    font-weight: 600;*!*/
/*!*}*!*/

/*.horizontalPoints {*/
/*    position: relative;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/

/*.horizontalPoints .points {*/
/*    position: relative;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: flex-start;*/
/*    align-items: center;*/
/*    margin-bottom: 24px;*/
/*}*/

/*.horizontalPoints .points .icon {*/
/*    width: 48px;*/
/*}*/

/*.horizontalPoints .points .text {*/
/*    width: calc(100% - 48px);*/
/*    padding-left: 16px;*/
/*}*/

/*.horizontalPoints .points .text .desc {*/
/*    font-weight: 500;*/
/*    font-size: 20px;*/
/*    line-height: 30px;*/
/*    color: #6A6A6A;*/
/*    margin-bottom: 0;*/
/*}*/

/*.bannercontainer {*/
/*    position: relative;*/
/*    height: 100%;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: flex-start;*/
/*}*/

/*.bannercontainer img {*/
/*    max-width: 100%;*/
/*    transition: all 0.3s ease;*/
/*}*/

/*.usCard {*/
/*    !*height: 500px;*!*/
/*    background-image: url("../../assets/images/developer-team.svg");*/
/*    background-repeat: no-repeat;*/
/*}*/

.shadow-style {
    transition: linear all 0.4s;
    box-shadow: 2px 2px 10px 0px #045270;
}

.datacard {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: calc(100% - 24px);
    margin-bottom: 24px;
    background: white;
    border-radius: 24px;
    padding: 24px;
}

.datacard .title {
    font-size: 20px;
    line-height: 30px;
}

.datacard .desc {
    font-size: 16px;
    line-height: 24px;
}

.tech-card .contact-card {
    transition: top ease 0.5s;
    top: 0;
    position: relative;
}

.tech-card:hover .contact-card {
    top: -10px
}

.card-shadow::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 1px 1px 10px 0 #045270 inset;
}

/*.challenge {*/
/*    background-image: url("../../assets/images/chess-3801531_1920.jpg");*/
/*    height: 100%;*/
/*}*/

.approach {
    background-color: #F9F9FE;
}

@media screen and (max-width: 991px) {
    .widgetBanTxtBtnImg {
        padding: 60px 0;

        .page-banner {
            flex-direction: column;
            justify-content: center;

            .content {
                width: 100%;
                justify-content: center;
                align-items: center;
                margin-bottom: 55px;

                .banner-title {
                    font-size: 32px;
                    line-height: 48px;
                    margin-bottom: 16px;
                    text-align: center;
                }

                .subtitle {
                    text-align: center;
                    font-size: 20px;
                    line-height: 30px;
                    margin-bottom: 24px;
                }
            }

            .icon {
                width: 100%;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .widgetBanTxtBtnImg {
        padding: 40px 0;

        .page-banner {
            .content {
                margin-bottom: 66px;

                .banner-title {
                    font-size: 24px;
                    line-height: 36px;
                    margin-bottom: 8px;
                }

                .subtitle {
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .widgetBanTxtBtnImg {
        padding: 40px 4px;
    }
}

@media screen and (max-width: 991px) {
    .widgetColTxtImgBul {
        padding: 60px 0;

        .bannercontainer {
            padding-right: 0;
        }

        .horizontalPoints {
            margin-bottom: 8px;

            .points {
                margin-bottom: 16px;

                .icon {
                    width: 32px;

                    .iconwrap {
                        width: 32px;
                        height: 32px;

                        img {
                            max-width: 55%;
                        }
                    }
                }

                .image {
                    width: 48px;

                    .imageWrap {
                        width: 48px;
                        height: 48px;

                        img {
                            max-width: 55%;
                        }
                    }
                }

                .text {
                    width: calc(100% - 32px);

                    .desc {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .widgetColTxtImgBul {
        padding: 40px 0;

        .horizontalPoints {
            .points {
                margin-bottom: 8px;
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .widgetColTxtImgBul {
        padding: 40px 4px;
    }
}

@media screen and (max-width: 1399px) {
    .widgetColrTxt {

        .bgBlue {
            .bgImg {
                background-position: 125% !important;

                .text-xl-start.text-lg-center.text-md-center.text-sm-center.text-center {
                    a {
                        display: block;
                        width: fit-content;
                        margin-right: 0px;
                    }

                    a:last-child {
                        margin-top: 24px;
                        margin-right: 0px;
                    }
                }
            }
        }

        .bgblack {
            .bgImg {
                background-position: 125% !important;

                .text-xl-start.text-lg-center.text-md-center.text-sm-center.text-center {
                    a {
                        display: block;
                        width: fit-content;
                        margin-right: 0px;
                    }

                    a:last-child {
                        margin-top: 24px;
                        margin-right: 0px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .widgetColrTxt {

        .bgBlue {
            .bgImg {
                background: none !important;

                .text-xl-start.text-lg-center.text-md-center.text-sm-center.text-center {
                    a {
                        display: inline-block;
                        width: fit-content;
                        margin-right: 24px;
                    }

                    a:last-child {
                        margin-top: 0px;
                        margin-right: 0px;
                    }
                }
            }
        }

        .bgblack {
            .bgImg {
                background: none !important;

                .text-xl-start.text-lg-center.text-md-center.text-sm-center.text-center {
                    a {
                        display: inline-block;
                        width: fit-content;
                        margin-right: 24px;
                    }

                    a:last-child {
                        margin-top: 0px;
                        margin-right: 0px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .widgetColrTxt {
        padding: 60px 0;

        .bgBlue {
            .bgImg {
                padding: 60px;

                .text-xl-start.text-lg-center.text-md-center.text-sm-center.text-center {
                    a {
                        display: block;
                        width: auto;
                        margin-right: 0px;
                        margin-top: 0px;
                    }
                }
            }
        }

        .bgblack {
            .bgImg {
                padding: 60px;

                .text-xl-start.text-lg-center.text-md-center.text-sm-center.text-center {
                    a {
                        display: block;
                        width: auto;
                        margin-right: 0px;
                        margin-top: 0px;
                    }
                }
            }
        }

        .title {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 24px;
        }

        .margin-toggle {
            margin-right: 24px;
            margin-bottom: 24px;
        }
    }
}

@media screen and (max-width: 767px) {
    .widgetColrTxt {
        padding: 40px 0;

        .bgBlue {
            .bgImg {
                padding: 40px;
            }
        }

        .bgblack {
            .bgImg {
                padding: 40px;
            }
        }

        .title {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;
        }

        .margin-toggle {
            margin-right: 0;
            margin-bottom: 16px;
        }
    }
}

@media screen and (max-width: 575px) {
    .widgetColrTxt {
        .bgBlue {
            .bgImg {
                padding: 40px;
            }
        }
        .bgblack {
            .bgImg {
                padding: 40px;
            }
        }
    }
}
