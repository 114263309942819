.stepwrap{
    padding-top: 40px;
    position: relative;
    width: 100%;
    overflow-x: auto;
    /*height: 300px;*/
    &::-webkit-scrollbar {
        display: none;
    }
}

/*.stepper{*/
/*    position: relative;*/
/*    width: max-content;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-content: center;*/
/*    !*justify-content: flex-start;*!*/
/*    !*padding: 0 40px;*!*/
/*    !*background: red;*!*/
/*    height: 350px;*/
/*    padding-left: 8%;*/
/*}*/

/*.step {*/
/*    position: relative;*/
/*    width: 240px;*/
/*    margin-right: 80px;*/
/*    !*padding-bottom: 200px;*!*/
/*    transition: all 0.3s ease;*/
/*    !*background-color: #F5F6FF;*!*/
/*    align-content: center;*/
/*    display: flex;*/
/*    flex-direction: column;*/


/*    &:last-child {*/
/*        margin-right: 0;*/
/*    }*/
/*}*/

/*.initial {*/
/*    position: relative;*/
/*    padding-bottom: 8px;*/
/*    !*align-items: center;*!*/
/*    !*display: flex;*!*/
/*    !*flex-direction: column;*!*/
/*}*/

/*.icon {*/
/*    width: 100%;*/
/*    margin-bottom: 10px;*/
/*    align-items: center;*/
/*}*/
/*.step.active img {*/
/*    transition: 0.3s;*/
/*}*/

/*.step img {*/
/*    border: 1px solid blue;*/
/*    padding: 10px;*/
/*    !*background-color: #ECEBEB;*!*/
/*    border-radius: 50%;*/
/*}*/

/*.img p {*/
/*    transform: rotate(45deg);*/
/*}*/

/*.step.active {*/
/*    !*width: 250px;*!*/
/*}*/

/*.step.active .description {*/
/*    display: block;*/
/*}*/

/*.title {*/
/*    !*position: absolute;*!*/
/*    !*left: 50%;*!*/
/*    !*bottom: 8px;*!*/
/*    !*transform: translateX(-50%);*!*/
/*    width: max-content;*/
/*    font-weight: 600;*/
/*    font-size: 20px;*/
/*    line-height: 30px;*/
/*    color: #363636;*/
/*    transition: all 0.3s ease;*/
/*}*/

/*.description {*/
/*    display: none;*/
/*    !*position: absolute;*!*/
/*    !*left: 0;*!*/
/*    width: 200px;*/
/*    font-weight: 500;*/
/*    font-size: 20px;*/
/*    line-height: 30px;*/
/*    !*background-color: #089688;*!*/
/*    color: #2B58A4;*/
/*}*/
