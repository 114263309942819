.icon-card {
    position: relative;
    width: 100%;
    height: calc(100% - 24px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
    border-radius: 24px;
    padding: 24px;
    overflow: hidden;
    transition: all 0.3s ease;
    background-color: #F5F6FF;
}
.icon-card .icon-wrap {
    position: relative;
    width: calc(100% + 48px);
    margin: -24px -24px 0 -24px;
}

.icon-card:hover {
    box-shadow: #035370 0px 0px 3px;
}

.icon-card .content .title {
    text-align: center;
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    color: #2B58A4;
    margin-top: 8px;
    margin-bottom: 8px;
}

.icon-card .content .text {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    color: #363636;
    margin-bottom: 0;
}

.image {
    max-width: 100%;
}
