.widgetBannerBgImg {
    position: relative;
    background: url(../../assets/images/startup-593344_1280.jpg) no-repeat;

    .gradientCover {
        position: relative;
        background: linear-gradient(90deg, #00000066 0%, #00000000 100%);

        .page-banner {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            min-height: 500px;
            padding: 60px 0;

            .content {
                width: 60%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                .title {
                    font-weight: 600;
                    font-size: 40px;
                    line-height: 60px;
                    color: white;
                    margin-bottom: 24px;
                }

                .subtitle {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 36px;
                    color: white;
                    margin-bottom: 32px;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .widgetBannerBgImg {
        .gradientCover {
            .page-banner {
                align-items: flex-start;
                min-height: 400px;
                padding: 60px 0;

                .content {
                    width: 100%;
                    justify-content: flex-start;
                    align-items: center;

                    .title {
                        font-size: 32px;
                        line-height: 48px;
                        margin-bottom: 16px;
                        text-align: center;
                    }

                    .subtitle {
                        font-size: 20px;
                        line-height: 30px;
                        margin-bottom: 24px;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .widgetBannerBgImg {
        .gradientCover {
            .page-banner {
                min-height: auto;
                padding: 40px 0;

                .content {
                    .title {
                        font-size: 24px;
                        line-height: 36px;
                        margin-bottom: 8px;
                    }

                    .subtitle {
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
}

.widgetContactForm {
    position: relative;
    padding: 40px 0;
    background: white;

    .contactForm {
        position: relative;

        .inputGroup {
            position: relative;
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 32px;
            }

            .formLabel {
                display: block;
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 0.4px;
                color: #363636;
                margin-bottom: 8px;
            }

            input, select, textarea {
                width: 100%;
                padding: 12px;
                min-height: 48px;
                background: #F8F8F8;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.2px;
                color: #363636;
                outline: none;
                border: none;
                box-shadow: none;
                border-radius: 8px;

                &:focus {
                    outline: none;
                    border: none;
                    box-shadow: none;
                }

                &::placeholder {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0.2px;
                    color: #6A6A6A;
                }
            }
        }

        .button-group {
            position: relative;

            .btn-solid {
                margin-bottom: 24px;
            }

            .info {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                color: #6A6A6A;
                text-align: center;
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .widgetContactForm {
        padding: 60px 0;

        .contactForm {
            .inputGroup {
                &:last-child {
                    margin-bottom: 24px;
                }

                .formLabel {
                    font-size: 16px;
                    line-height: 24px;
                }

                input, select, textarea {
                    font-weight: 500;

                    &::placeholder {
                        font-weight: 500;
                    }
                }
            }

            .button-group {
                .btn-solid {
                    margin-bottom: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .widgetContactForm {
        padding: 40px 0;

        .contactForm {
            .inputGroup {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 16px;
                }
            }

            .button-group {
                .btn-solid {
                    margin-bottom: 8px;
                }

                .info {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .widgetContactForm {
        padding: 40px 4px;
    }
}
