.footer {
    position: relative;
    padding: 80px 0 32px;
    background: linear-gradient(261.05deg, #035370 2.93%, #035370 62.53%,#089688 97.54%);
    color: white;
}

.footer .top {
    position: relative;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 40px;
}

.footer .bottom {
    position: relative;
}

.footer .bottom .top-content .privacy {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.footer .bottom .top-content .privacy .copyright {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: white;
    margin-bottom: 0;
}

.footer .bottom .top-content .privacy .copyright a, .footer .bottom .top-content .privacy .copyright a:hover {
    font-weight: 500;
    color: white;
    text-decoration: none;
    border-bottom: 1px solid white;
}